.hero-wrap-home {
    background-size: cover; /* or use 'contain' based on your requirement */
    background-repeat: no-repeat;
    height: auto;
}

@media (max-width: 480px) {
    .hero-wrap-home {
        background-size: contain;
        height: auto;
    }
}

.banner-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
