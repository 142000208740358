.destination {
  border: 1px solid #ddd;
  background: #fff;
  margin: 10px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.destination .img {
  height: 200px;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid #ddd;
}

.destination .text {
  padding: 15px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

@media (max-width: 480px) {
  .slick-slide {
    justify-content: center;
    align-items: center;
  }

  .slick-prev,
  .slick-next,
  .slick-dots {
    display: none !important;
  }
}

.slick-prev,
.slick-next {
  background: #dc3545;
  color: #fff;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.item {
  display: flex !important;
  justify-content: center;
  align-items: center;
}