body {
    font-family: 'Poppins', Arial, sans-serif;
    font-weight: 400;
    line-height: 1.8;
    color: #212529;
    background-color: #fff;
}

section {
    padding: 4em 0;
}

.overlay {
    background: rgba(0, 0, 0, 0.5);
}

.container {
    max-width: 1140px;
    margin-top: 40;
    align-items: center;
}

.heading-section {
    margin-bottom: 30px;
}

.ftco-animate {
    animation: fadeInUp 1s both;
}

.icon {
    font-size: 3em;
    color: #fff;
}

.text {
    padding: 20px;
    background: #fff;
}

.text p {
    margin-bottom: 10px;
}

.text .price {
    color: #f15a24;
    font-size: 1.2em;
    font-weight: 700;
}

.text .days {
    color: #000;
}

.img {
    position: relative;
    display: block;
    height: 250px;
    background-size: cover;
    background-position: center center;
    border-radius: 5px;
}

.img img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    color: #fff;
}

.ftco-section {
    padding: 7em 0;
    position: relative;
}
